import React from 'react'

function Main() {
  return (
    <main class="main">
    <section class="promo">
        <div class="container">
            <div class="promo-wrapper">
                <div class="promo-content">
                    <h1 class="promo-title">Play the Best Social Casino Games in Canada</h1>
                    <div class="promo-txt">
                        <h2>
                            Experience free and fun online casino games with Fun Sphere NZ! Enjoy top slots,
                            poker,
                            and more without real money wagering. Play now!
                        </h2>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section class="game">
        <div class="container">
            <div class="game-wrapper">
                <a href="/game1/" class="game-container game1">
                    <img src="/mata-hari.jpg" alt=""/>
                    <span class="game-txt">Mata Hari</span>
                </a>

                <a href="/game2/" class="game-container game2">
                    <img src="/5xmagic-.jpg" alt="/"/>
                    <span class="game-txt">5x Magic</span>
                </a>

                <a href="/game3/" class="game-container game3">
                    <img src="/game3.jpeg" alt=""/>
                    <span class="game-txt">Amazon Queen Slot</span>
                </a>
            </div>
        </div>
    </section>
</main>
  )
}

export default Main