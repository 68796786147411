import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Contact() {
  return (
  <>
  <Header/>
  <section>
            <div class="container">
                <div class="wrapper">
                    <div class="image-contact">
                        <div>
                            <h1 style={{ color: "#fff" }}>Contact Us</h1>
                            <p style={{ color: "#fff" }}>We value your questions and feedback immensely! Your input is highly
                                appreciated. Whether you have inquiries or
                                suggestions, our dedicated team is here to assist you and ensure that your experience
                                with our platform is enjoyable.</p>
                            <a style={{ color: "#fff" }} href="mailto:info@funspherenz.com">info@funspherenz.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
  </>
  )
}

export default Contact